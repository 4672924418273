<template>
  <div class="item-page">
    <div class="item-page__header" v-if="loading">
      <v-skeleton-loader
          type="list-item-avatar-three-line"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__body" v-if="loading">
      <v-skeleton-loader
          type="list-item, list-item, list-item, list-item"
          width="50%"
          height="100%"
      />
      <v-skeleton-loader
          type="list-item-avatar, list-item-avatar"
          width="50%"
          height="100%"
      />
    </div>
    <div class="item-page__header" v-if="!loading">
      <img :src="require('@/assets/img/pages/product.png')" alt="" class="mr-9">
      <div class="item-page__name">
        <v-text-field
            class="evi-text-field"
            color="#44D370"
            v-model="product.name"
            disabled
        >
          <template v-slot:label>
            Название продукта
          </template>
        </v-text-field>
        <div class="item-page__actions  mt-1" v-if="hasRight('products.manage')">
          <v-btn text class="evi-button-text" @click="removeProductModal = true">
            <img class="mr-2" :src="require('@/assets/img/users/red.svg')" alt="">
            Удалить
          </v-btn>
        </div>
      </div>
      <div class="item-page__close" @click="$router.push({path: '/products'})">
        <v-icon size="20">mdi-close</v-icon>
      </div>
    </div>
    <div class="item-page__body" v-if="!loading">
      <div class="item-page__edit">
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="product.id"
            disabled
            hide-details
        >
          <template v-slot:label>
            ID продукта (артикул)
          </template>
        </v-text-field>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="name"
            @change="editProductPage"
            :disabled="!hasRight('products.manage')"
            hide-details
        >
          <template v-slot:label>
            Название продукта
          </template>
        </v-text-field>
        <v-select
            class="evi-select mb-7"
            color="#44D370"
            v-model="product.type"
            :items="types"
            item-text="name"
            item-value="value"
            label="Тип"
            item-color="green"
            readonly
            hide-details
        >
          <template v-slot:append>
                <span class="evi-select__arrow" v-show="false">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
          </template>
        </v-select>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            v-model="weight"
            @change="editProductPage"
            :disabled="!hasRight('products.manage')"
            v-if="product.type === 'product'"
            hide-details
        >
          <template v-slot:label>
            Вес в граммах
          </template>
        </v-text-field>
        <div>
          <v-text-field
              class="evi-text-field mb-4"
              color="#44D370"
              v-model="price"
              v-mask="currencyMask"
              @change="editProductPage"
              v-if="hasRight('products.manage')"
              hide-details
          >
            <template v-slot:label>
              Новая стоимость
            </template>
          </v-text-field>
          <div class="current-price">Текущая стоимость: <span>{{ product.price | VMask(currencyMask) }}</span></div>
        </div>
      </div>
      <div class="item-page__added" v-if="product.type === 'product'">
        <h3>Размеры</h3>
        <v-btn v-if="hasRight('products.manage')" class="evi-button-green evi-button-green--fill mt-10 mb-10" @click="addSizeModal = true">
          Добавить размер
        </v-btn>
        <div>
          <div class="deal-product" v-for="item in product.sizes" :key="item.id">
            <v-menu offset-y :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <div class="deal-product__dots" v-on="on" :bind="attrs" v-if="hasRight('products.manage')">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </div>
              </template>
              <v-list class="deal-product__menu">
                <v-list-item-group>
                  <v-list-item @click="editProductSizeModalOpen(item)">
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#008A80">mdi-pencil</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Редактировать</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteProductSizeModalOpen(item)">
                    <v-list-item-icon class="mr-2">
                      <v-icon color="#ED4245">mdi-trash-can-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Удалить</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <div class="deal-product__body">
              <v-text-field
                  class="evi-text-field mb-5"
                  color="#44D370"
                  v-model="item.value"
                  disabled
                  hide-details
              >
                <template v-slot:label>
                  Значение
                </template>
              </v-text-field>
              <v-text-field
                  class="evi-text-field mb-7"
                  color="#44D370"
                  v-model="item.weight"
                  disabled
                  hide-details
              >
                <template v-slot:label>
                  Вес в граммах
                </template>
              </v-text-field>
              <div class="deal-product__price">
                <span class="price-title">Стоимость за единицу</span>
                <span class="price-value">{{ item.price | VMask(currencyMask) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="removeProductSizeModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление: {{ selectedSize.value }}
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить размер товара?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeProductSizeModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeProductSize"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addSizeModal"
        max-width="600"
    >
      <v-card class="modal-create">
        <v-form ref="form" v-model="valid">
          <v-card-title class="d-flex justify-center">
            <span class="text-h5 text-center mb-11">Добавление размера</span>
          </v-card-title>
          <v-card-text class="modal-create__form">
            <div class="close-dialog" @click="closeDialog">
              <img :src="require('@/assets/img/common/close-dialog.svg')" />
            </div>
            <v-col
                cols="12"
            >
              <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="sizeName"
                  placeholder="Размер"
                  :rules="nameRules"
              >
                <template v-slot:label>
                  Размер
                  <span class="important">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="sizeWeight"
                  placeholder="Размер"
                  :rules="nameRules"
              >
                <template v-slot:label>
                  Вес в граммах
                  <span class="important">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="sizePrice"
                  placeholder="Цена"
                  v-mask="currencyMask"
              >
                <template v-slot:label>
                  Цена
                </template>
              </v-text-field>
            </v-col>
          </v-card-text>
          <v-card-actions class="modal-create__actions">
            <v-btn class="evi-button-green evi-button-green--fill" @click="create">
              Добавить размер
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editSizeModal"
        max-width="600"
    >
      <v-card class="modal-create">
        <v-form ref="form" v-model="valid">
          <v-card-title class="d-flex justify-center">
            <span class="text-h5 text-center mb-11">Редактирование размера</span>
          </v-card-title>
          <v-card-text class="modal-create__form">
            <div class="close-dialog" @click="closeEditSizeDialog">
              <img :src="require('@/assets/img/common/close-dialog.svg')" />
            </div>
            <v-col
                cols="12"
            >
              <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="editSizeName"
                  placeholder="Размер"
                  :rules="nameRules"
              >
                <template v-slot:label>
                  Размер
                  <span class="important">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="editSizeWeight"
                  placeholder="Размер"
                  :rules="nameRules"
              >
                <template v-slot:label>
                  Вес в граммах
                  <span class="important">*</span>
                </template>
              </v-text-field>
            </v-col>
            <v-col
                cols="12"
            >
              <v-text-field
                  class="evi-text-field"
                  color="#44D370"
                  v-model="editSizePrice"
                  placeholder="Цена"
                  v-mask="currencyMask"
                  :key="priceKey"
              >
                <template v-slot:label>
                  Цена
                </template>
              </v-text-field>
            </v-col>
          </v-card-text>
          <v-card-actions class="modal-create__actions">
            <v-btn class="evi-button-green evi-button-green--fill" @click="editProductSize">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="removeProductModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление: {{product.name}}
        </v-card-title>
        <v-card-text >Вы действительно хотите удалить продукт?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeProductModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeProduct"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import {hasRight} from '@/utils/access/hasRight';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  allowNegative: true,
});

export default {
  name: "ProductPage",
  data: () => ({
    name: '',
    weight: '0',
    sizeWeight: '',
    removeProductModal: false,
    removeProductSizeModal: false,
    editSizeModal: false,
    selectedSize: {},
    price: null,
    sizeName: '',
    editSizeName: '',
    editSizePrice: '',
    editSizeWeight: '0',
    sizePrice: '',
    currencyMask,
    addSizeModal: false,
    priceKey: 0,
    valid: false,
    nameRules: [(v) => !!v || 'Введите значение'],
  }),
  computed: {
    ...mapState("products", ['product', 'types']),
    ...mapState("common", ["loading"]),
  },
  methods: {
    ...mapActions({
      getProduct: 'products/getProduct',
      editProduct: 'products/editProduct',
      deleteProduct: 'products/deleteProduct',
      createSize: 'products/createSize',
      editSize: 'products/editSize',
      removeSize: 'products/removeSize',
    }),
    hasRight: hasRight,
    priceNum(price) {
      if (price) {
        let priceArray = price.split(" ");

        let sum = '';

        for (let i = 0; i < priceArray.length; i++) {
          sum += priceArray[i];
        }

        return parseFloat(sum);
      } else {
        return 0;
      }
    },
    getData() {
      let id = this.$route.params.id;
      this.getProduct(id);
    },
    deleteProductSizeModalOpen (payload) {
      this.selectedSize = payload;
      this.removeProductSizeModal = true;
    },
    editProductSizeModalOpen (payload) {
      this.priceKey++;
      this.selectedSize = payload;
      this.editSizeName = payload.value;
      this.editSizePrice = payload.price;
      this.editSizeWeight = payload.weight;
      this.editSizeModal = true;
    },
    removeProductSize () {
      this.removeSize({
        productId: this.product.id,
        productSize: this.selectedSize.id,
      }).then(() => {
        this.selectedSize = {};
        this.removeProductSizeModal = false;
      });
    },
    editProductSize () {
      if (this.validate()) {
        this.editSize({
          productId: this.product.id,
          productSize: this.selectedSize.id,
          value: this.editSizeName,
          weight: this.editSizeWeight,
          price: this.priceNum(this.editSizePrice),
        }).then(() => {
          this.closeEditSizeDialog();
        });
      }
    },
    closeEditSizeDialog() {
      this.editSizeName = '';
      this.editSizePrice = '';
      this.selectedSize = {};
      this.editSizeModal = false;
      this.$refs.form.resetValidation();
    },
    editProductPage() {
      this.editProduct({
        name: this.name,
        weight: this.weight,
        price: this.priceNum(this.price) || this.product.price,
        id: this.product.id,
      });
    },
    removeProduct() {
      this.deleteProduct(this.product).then(() => {
        this.removeProductModal = false;
        this.$router.push({path: '/products'});
      })
    },
    closeDialog () {
      this.addSizeModal = false;
      this.sizeName = '';
      this.sizeWeight = '';
      this.sizePrice = '';
      this.$refs.form.resetValidation();
    },
    validate() {
      return !!this.$refs.form.validate();
    },
    create () {
      if (this.validate()) {
        this.createSize({
          productId: this.product.id,
          value: this.sizeName,
          weight: this.sizeWeight,
          price: this.priceNum(this.sizePrice),
        }).then(() => {
          this.closeDialog();
        });
      }
    }
  },
  watch: {
    product(val) {
      this.name = val.name;
      this.weight = val.weight;
      this.sizeWeight = val.weight;
      this.price = val.price.toString();
    },
    '$route.params.id': {
      handler: function () {
        this.getData();
      },
      deep: true,
      immediate: true
    },
  }
}
</script>

<style lang="scss" scoped>
.item-page__edit {
  border-right: 1px solid #EBEBEC;
}
</style>
